.contact {
  & .left-container {
    color: #fff;
    text-align: center;
    background-color: #0d7583;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    display: flex;
  }

  & .container {
    background-color: #fff;
    width: 100%;
    padding: 15px;
    position: relative;
    overflow: hidden;
  }

  & h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  & .form-container {
    flex-direction: column;
    gap: 16px;
    display: flex;
  }

  & .form-single-column {
    flex: 1;
  }

  & input, & textarea {
    background-color: #eee;
    border: none;
    border-radius: 14px;
    width: 100%;
    margin: 8px 0;
    padding: 12px 15px;
    font-size: .8rem;
  }

  & input:focus, & textarea:focus {
    outline: 1px solid #0d7583;
  }

  & button {
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #0d7583;
    border: 1px solid #0d7583;
    border-radius: 14px;
    padding: 12px 45px;
    font-size: 12px;
    font-weight: bold;
    transition: transform 80ms ease-in;
  }

  & button:hover {
    opacity: .7;
  }

  @media only screen and (width <= 600px) {
    & .form-container {
      flex-direction: column;
    }
  }

  @media only screen and (width >= 600px) and (width <= 900px) {
    & .form-container {
      flex-direction: column;
    }
  }

  @media only screen and (width >= 900px) {
    & .form-container {
      flex-direction: row;
    }
  }

  & form p {
    text-align: left;
  }

  & .error {
    color: red;
    font-size: .9em;
  }

  & .general-error {
    color: red;
    margin-top: 10px;
    font-size: 1em;
  }

  & .success-message {
    color: green;
    margin-top: 10px;
    font-size: 1em;
  }

  & input[type="radio"]:focus {
    outline: none;
  }
}
/*# sourceMappingURL=index.1dd36f98.css.map */
