.contact {
  .left-container{
    background-color: #0d7583;
    color: #ffffff;
    padding: 16px;
    text-align: center;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 15px;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .form-container {
    display: flex;
    gap: 16px;
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
  }

  .form-single-column {
    flex: 1;
  }

  input,
  textarea {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%; /* Asegúrate de que los inputs ocupe el ancho completo */
    font-size: 0.8rem;
    border-radius: 14px;
  }

  input:focus,
  textarea:focus {
    outline: 1px solid #0d7583;
  }

  button {
    border-radius: 14px;
    border: 1px solid #0d7583;
    background-color: #0d7583;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    /* Oculta las secciones no esenciales en móvil */
    /* .left-container {
      display: none;
    } */

    .form-container {
      flex-direction: column; /* Asegura que todo esté en columna */
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    .form-container {
      flex-direction: column; /* Mantiene las columnas en pantallas medianas */
    }
  }

  @media only screen and (min-width: 900px) {
    .form-container {
      flex-direction: row; /* Cambia a filas en pantallas grandes */
    }
  }

  form p {
    text-align: left;
  }

  .error {
    color: red;
    font-size: 0.9em;
  }

  .general-error {
    margin-top: 10px;
    color: red;
    font-size: 1em;
  }

  .success-message {
    margin-top: 10px;
    color: green;
    font-size: 1em;
  }

  input[type="radio"]:focus {
    outline: none;
  }
}
